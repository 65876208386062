const ContentEN = () => {
  return (
    <div className="page-content" key="page-content">
        <div className="content">
            <p>The place name register contains about 33.000 place names from all over Greenland. These are authorized for
                official use by the Greenland Place Name Committee, which has been established by law by Inatsisartut.</p>
            <p>The Greenland Place Names Committee approves place names for official use by following their principles for
                authorization of place names.</p>
            <p>Starting in 2020, the place name register is undergoing an update in order to ensure the quality of all place
                names in the register. That is why not all place names are publicly available.</p>
            <p>The register is administered by Oqaasileriffik (the Language Secretariat of Greenland) on behalf of the
                Greenland Place Names Committee.</p>
            <p>Inquiries regarding place names are sent to
                <a href="mailto: oqaasileriffik@oqaasileriffik.gl"> oqaasileriffik@oqaasileriffik.gl </a>
            </p>
        </div>
    </div>
  )
}

export default ContentEN