import React from "react";
import { isMobileWidth } from "../../../utils/AppUtils";
import * as intl from "@arcgis/core/intl";

type onLocaleChange = (locale: string) => void

export interface LocaleProps {
    onLocaleChange: onLocaleChange;
}

export default class LocaleSwitcher extends React.Component<LocaleProps> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    private changeLocaleParameter = (locale: string): void => {
        intl.setLocale(locale);
        this.props.onLocaleChange(locale)
    }

    public render(): JSX.Element {

        return (
            <>
                <div className="header-btn locale-btn" title="GL" onClick={()=>this.changeLocaleParameter("gl")}>
                    <img src={require("./images/gl.png")} alt="gl"></img>
                </div>
                <div className="header-btn locale-btn" title="DA" onClick={()=>this.changeLocaleParameter("da")}>
                    <img src={require("./images/da.png")} alt="da"></img>
                </div>
                <div className="header-btn locale-btn" title="EN" onClick={()=>this.changeLocaleParameter("en")}>
                    <img src={require("./images/en.png")} alt="en"></img>
                </div>
            </>
        );
    }
}