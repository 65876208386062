import React from "react";
import { isMobileWidth, resizeHandler } from "../utils/AppUtils";
import About from "./widgets/about/about";
import LocaleSwitcher from "./widgets/locale/locale-switcher";
import * as intl from "@arcgis/core/intl";
import config from "../config/config.json";

export type Messages = {
  title: string,
  subtitle: string,
  help: string
}

export interface HeaderState {
  isMobileView: boolean;
  showMenu: boolean;
  locale: string;
  messages: Messages
}

export default class Header extends React.Component<any, HeaderState> {

  constructor(props: any) {
    super(props);
    this.state = { 
      isMobileView: isMobileWidth(),
      showMenu: false,
      locale: intl.getLocale(),
      messages: {
        title: "Greenland's Place Names",
        subtitle: "Greenland Language Secretariat",
        help: "Help"
      }
    };

    intl.onLocaleChange(locale => {
      if(locale === this.state.locale) return;
      console.log("header locale changed to: ", locale);
      this.updateHeaderMessages(locale)
    });

    resizeHandler(() => {
      this.setState({ isMobileView: isMobileWidth() })
      console.log("header isMobileView:", this.state.isMobileView)
    });
  }

  componentDidMount(){
    this.updateHeaderMessages(this.state.locale);
  }

  updateHeaderMessages = (locale: string): void => {
    intl.fetchMessageBundle("/t9n/header").then((messages:Messages) => {
      console.log(messages)
      this.setState({ showMenu: false, locale: locale, messages: messages })
    })
  }

  localeChanged = (locale: string): void => {
    console.log(`locale switcher returned new locale: ${locale}`)
    // intl.fetchMessageBundle("/t9n/header").then((messages:Messages) => {
    //   console.log(messages)
    //   this.setState({ showMenu: false, locale: locale, messages: messages })
    // })
  }
  
  onClickMenu = (): void => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  hideMobileMenu = (): void => {
    this.setState({ showMenu: false})
  }

  render() {
    const customClasses = `header-part${this.state.isMobileView ? " mobile-header":"" }${this.state.showMenu ? " mobile-menu-open":""}`;
    
    return (
      <>
        <div className={customClasses}>
            <div className="header-title-part">
                <div className="title-part">{this.state.messages.title}</div>
                <div className="title-sub-part">{this.state.messages.subtitle}</div>
            </div>
            <div className="buttons-menu esri-icon-drag-horizontal" onClick={this.onClickMenu}></div>
            <div className="logos-part">
                <div className="header-logo header-logo-1"></div>
                <div className="header-logo header-logo-2"></div>
                <div className="header-logo header-logo-3"></div>
                <div className="header-logo header-logo-4"></div>
                <div className="header-logo header-logo-5"></div>
                <div className="header-logo header-logo-6"></div>
            </div>
            <div className="version-section">{config.header.version || "Version 1.0"}</div>
            <div className="buttons-part">
                <About onClosed={this.hideMobileMenu}></About>
                <LocaleSwitcher onLocaleChange={this.localeChanged}></LocaleSwitcher>
            </div>
        </div>
      </>
    );
  }
}

  