import React, { useRef, useEffect, useState } from "react";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import Home from '@arcgis/core/widgets/Home';
import Extent from '@arcgis/core/geometry/Extent';
import LocatorSearchSource from "@arcgis/core/widgets/Search/LocatorSearchSource";
import Search from "@arcgis/core/widgets/Search";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from '@arcgis/core/widgets/Expand';
import MapView from "@arcgis/core/views/MapView";
import Portal from "@arcgis/core/portal/Portal";
import PortalBasemapsSource from "@arcgis/core/widgets/BasemapGallery/support/PortalBasemapsSource";
import WebMap from "@arcgis/core/WebMap";
import config from "../config/config.json";
import { isMobileWidth, resizeHandler } from "../utils/AppUtils";
import * as intl from "@arcgis/core/intl";
import { watch } from "@arcgis/core/core/reactiveUtils";

export type Messages = {
    locale: string,
    widgets:{
        layerList: {
            expand: string,
            callapse: string
        },
        legend: {
            expand: string,
            callapse: string
        },
        bookmarks: {
            expand: string,
            callapse: string
        },
        search: {placeholder: string}
    }
};

function Map() {
    const [stateLocale, setLocale]: any = useState(()=>{
        return intl.getLocale()
    })
    const [messages, setMessages]: any = useState({
        locale: "en",
        widgets:{
          layerList: {
            expand: "Expand layers list",
            callapse: "Callapse layers list"
          },
          legend: {
            expand: "Expand legend",
            callapse: "Callapse legend"
          },
          bookmarks: {
            expand: "Expand bookmarks",
            callapse: "Callapse bookmarks"
          },
          search: {placeholder: "Search"}
        }
    })
    const [stateExtent, setExtent]: any = useState(()=>{return new Extent(config.map.viewOptions.extent)})
    const [isMobileView, setIsMobileView]: any = useState(()=>{
        return isMobileWidth()
    })

    const updateMapMessages = (locale: string): void => {
        intl.fetchMessageBundle("/t9n/map").then((msgs:Messages) => {
            console.log("setMapMessages", msgs)
            setLocale(locale)
            setMessages(msgs)
        })
    };

    useEffect(() => {
        updateMapMessages(stateLocale)
        intl.onLocaleChange(locale => {
            updateMapMessages(locale)
            console.log("map locale changed to: ", locale);
        });
    },[])

    const mapDiv = useRef(null);

    useEffect(() => {
        if (mapDiv.current) {
            /**
             * Initialize application
             */
            resizeHandler(() => {
                setIsMobileView(()=>{return isMobileWidth()})
                console.log("map isMobileView:", isMobileView)
            });

            const webmap = new WebMap({
                portalItem: config.map.portalItemOptions
            });

            const view = new MapView({
                container: mapDiv.current,
                extent: config.map.viewOptions.extent,
                map: webmap
            });
            view.goTo(stateExtent)

            watch(() => view?.extent,
            () => {
                setExtent(view?.extent)
            });

            /* ----- Home --------*/
            const homeWidget = new Home({
                view: view
            });
            view.ui.add(homeWidget, "top-left");

            /* ---- ScaleBar -------- */
            const scaleBar = new ScaleBar({
                view: view,
                unit: "metric"
            });
            view.ui.add(scaleBar, "bottom-left");

            /* ---- BasemapGallery -------- */
            const basemapsSource = new PortalBasemapsSource({
                portal: new Portal({url: config.map.portalItemOptions.portal.url}),
                query: { id: config.map.portalBasemapsGroupId }
              });
            const basemapGallery = new BasemapGallery({
                view: view,
                source: basemapsSource
            });
            const basemapExpand = new Expand({
                view: view,
                group: "rightTop",
                content: basemapGallery,
                collapseTooltip: messages.widgets.layerList.callapse,
                expandTooltip: messages.widgets.layerList.expand
            });
            view.ui.add(basemapExpand, "top-right");

            /* ---- LayerList -------- */
            const layerList = new LayerList({
                view: view
            });
            const layerExpand = new Expand({
                view: view,
                group: "rightTop",
                content: layerList,
                collapseTooltip: messages.widgets.layerList.callapse,
                expandTooltip: messages.widgets.layerList.expand
            });
            view.ui.add(layerExpand, "top-right");

            /* ---- Legend -------- */
            const list: any[] = [];
            webmap.layers.every(function (layer: any) {
                list.push({
                    layer: layer,
                    title: layer.title
                });
                return true;
            });
            const legend = new Legend({
                view: view,
                layerInfos: list
            });
            const legendExpand = new Expand({
                view: view,
                group: "rightTop",
                content: legend,
                collapseTooltip: messages.widgets.legend.callapse,
                expandTooltip: messages.widgets.legend.expand
            });
            view.ui.add(legendExpand, "top-right");
            
            /* ---- Search -------- */
            const locatorSource = new LocatorSearchSource({
                url: config.map.widgets.search.url,
                singleLineFieldName: "SingleLine",
                outFields: ["LongLabel"],
                popupTemplate: {
                    title: "{LongLabel}"
                },
                searchTemplate: "{SingleLine}",
                placeholder: messages.widgets.search.placeholder
            })

            const searchWidget = new Search({
                view: view,
                sources: [locatorSource],
                includeDefaultSources: false
            });

            view.ui.add(searchWidget, "manual");

            /* ---- Bookmarks -------- */
            const bookmarks = new Bookmarks({
                view,
                editingEnabled: true // allows bookmarks to be added, edited, or deleted
            });

            const bookmarksExpand = new Expand({
                view,
                content: bookmarks,
                collapseTooltip: messages.widgets.bookmarks.callapse,
                expandTooltip: messages.widgets.bookmarks.expand
            });

            // Add the widget to the top-right corner of the view
            view.ui.add(bookmarksExpand, "top-right");

        }
    }, [messages, isMobileView]);

    let mapClasses = `mapDiv${isMobileView ? " mobile":""}`;
    return (<div className={mapClasses} ref={mapDiv}></div>);
}

export default Map;
