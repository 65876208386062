import * as intl from "@arcgis/core/intl";
import Header from "./header/header";
import Map from "./map/map"
import "./App.scss";

function App() {
  const supportedLocales = ['gl', 'da', 'en'];

  const readUrlForLocale = () => {
    const defaultLocale = "da";
    const queryString = window.location.search.substring(1);
    if (!queryString) {
        return defaultLocale; 
    }
    const matches = queryString.match(/&?locale=(\D+)(&|$)/);
    if (matches && supportedLocales.indexOf(matches[1]) > -1) {
        return matches[1];
    }
    return defaultLocale;
  }

  const localeLoader = {
    pattern: "/t9n/",
    async fetchMessageBundle(bundleId: string, locale: any) {
      const [, widgetName] = bundleId.split("/t9n/");
      
      if (supportedLocales.indexOf(locale) === -1){
        locale = intl.normalizeMessageBundleLocale(locale);
      }
      const fileName = locale ? `strings_${locale}` : "strings";
      const bundlePath = `./t9n/${widgetName}/${fileName}.json`;
      const response = await fetch(bundlePath);
      return response.json();
    }
  }
  intl.registerMessageBundleLoader(localeLoader);

  const urlLocale = readUrlForLocale();
  if(intl.getLocale() !== urlLocale){
    intl.setLocale(urlLocale);
  }
  
  return (
  <>
    <Header></Header>
    <Map></Map>
  </>
  );
}

export default App;