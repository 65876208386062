const ContentDA = () => {
  return (
    <div className="page-content" key="page-content">
        <div className="content">
            <p>Stednavne registeret indeholder omkring 33.000 stednavne over hele Grønland. Disse er autoriserede til
                officielt brug af Grønlands Stednavnenævn, der er nedsat ved lov af Inatsisartut.</p>
            <p>Grønlands Stednavnenævn godkender stednavne til officielt brug ved at følge deres principper for autorisation
                af stednavne. </p>
            <p>Med begyndelse i 2020 er stednavneregisteret under en datavask med henblik på at kvalitetssikre alle
                stednavne i registeret. Derfor er ikke alle stednavne offentligt tilgængelige.</p>
            <p>Registeret administreres af Oqaasileriffik (Grønlands Sprogsekretariat) på vegne af Grønlands Stednavnenævn.
            </p>
            <p>Henvendelser vedr. stednavne sendes til
                <a href="mailto: oqaasileriffik@oqaasileriffik.gl"> oqaasileriffik@oqaasileriffik.gl </a>
            </p>
        </div>
    </div>
  )
}

export default ContentDA