const ContentGL = () => {
  return (
    <div className="page-content" key="page-content">
        <div className="content">
            <p>Nunat aqqisa allattorsimaffiat 33.000-it missiliorlugit amerlatigisunik nunat aqqinik nalunaarsukkanik
                imaqarpoq. Taakku Inatsisartunit pilersinneqarsimasunit Nunat Aqqinik Aalajangiisartunit pisortatigoortumik
                atuuttussatut akuerisaapput.</p>
            <p>Nunat Aqqinik Aalajangiisartut nunat aqqinik atortussanngortitsisarnermut tunngavitsik malillugu
                akuersisarput.</p>
            <p>2020 aallarnerfigalugu nunat aqqisa allattorsimaffiat aaqqissuunneqarluni aallartinneqarpoq.
                Allattorsimaffiup imarisaasa qulakkeerluarneqarnissaat pitsaasuunissaallu aaqqissuussinerup siunertarivaa
                taamaattumik nunat aqqi nalunaarsukkat tamarmik nuisatinneqanngillat.</p>
            <p>Allattorsimaffik Oqaasileriffimmit aqunneqarpoq.</p>
            <p>Nunat aqqi pillugit saaffiginnissutit uunga nassiunneqassapput:
                <a href="mailto: oqaasileriffik@oqaasileriffik.gl"> oqaasileriffik@oqaasileriffik.gl </a>
            </p>
        </div>
    </div>
  )
}

export default ContentGL