import React from "react";
import * as intl from "@arcgis/core/intl";
import { isMobileWidth } from "../../../utils/AppUtils";
import ContentDA from "./content-da";
import ContentEN from "./content-en";
import ContentGL from "./content-gl";

export type onClosed = () => void
export type Messages = {
    about: string
}

export interface AboutProps {
    onClosed: onClosed;
}

export interface AboutState {
    showContent: boolean;
    messages: Messages;
    locale: string;
}

export default class About extends React.Component<AboutProps, AboutState> {
    constructor(props: any) {
        super(props);
        this.state = { 
            showContent: false,
            messages: {about: "About"},
            locale: intl.getLocale()
        };

        intl.onLocaleChange(locale => {
            if(locale === this.state.locale) return;
            console.log("about locale changed to: ", locale);
            this.updateAboutMessages(locale)
        });
        
    }

    componentDidMount(){
        this.updateAboutMessages(this.state.locale);
    }

    updateAboutMessages = (locale: string): void => {
        intl.fetchMessageBundle("/t9n/header/widgets/about").then((msgs:Messages) => {
            console.log(msgs)
            this.setState({messages: msgs, locale: locale})
        })
    }

    public render(): JSX.Element {
        
        const pageContent = this._renderAboutContent();
        const closeButton = (
            <div className="close-button esri-icon-close" key="close-button"
                onClick={this._closeWidget}
            ></div>
        );

        const customClasses = `about-content ${!isMobileWidth() ? " positioned" : ""}`
        return (
        <>
            <div className="header-btn about-btn" title={this.state.messages.about} onClick={this._onAboutButtonClick}>
                <span className="esri-icon-notice-round"></span>
                <span className="btn-label">{this.state.messages.about}</span>
            </div>
            {
                this.state.showContent &&
                <div className={customClasses}>
                    <div className="content-overlay">
                        <div className="content-frame">
                            {closeButton}
                            {pageContent}
                        </div>
                    </div>
                </div>
            }
        </>
        );
    }

    private _renderAboutContent(): JSX.Element {
        if(this.state.locale === "da"){
            return <ContentDA></ContentDA> 
        }
        if(this.state.locale === "gl"){
            return <ContentGL></ContentGL> 
        }
        return <ContentEN></ContentEN>
    }

    private _onAboutButtonClick = (): void => {
        this.setState({
            showContent: !this.state.showContent
        })
    }

    private _closeWidget = (): void => {
        this.setState({
            showContent: false
        })
        this.props.onClosed();
    }
}